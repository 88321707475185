import './App.css';
import { RedocStandalone } from 'redoc';

function App() {
  return (
    <RedocStandalone 
      spec="/spec.yml"
      options={{
        hideDownloadButton: true,
        theme: { 
          colors: { 
            primary: { 
              main: '#170a07' 
            },
            responses: {
              success: {
                backgroundColor: "#D3FFEB",
                color: "#19D681"
              },
              error: {
                backgroundColor: "#FFCED0",
                color: "#FF2934"
              },
              warning: {
                backgroundColor: "#FFF8D4",
                color: "#FEDE48"
              }
            }
          },
          sidebar: {
            backgroundColor: "#FEDE48",
            textColor: "#170A07",
          },
          rightPanel: {
            backgroundColor: '#382E2C'
          },
          logo: {
            gutter: '20px'
          },
          typography: {
            headings: {
              fontWeight: "bold"
            }
          }
        }
      }}
    />
  );
}

export default App;
